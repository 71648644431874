(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ticketgames/ticket-mappings/assets/javascripts/product-mappings.js') >= 0) return;  svs.modules.push('/components/ticketgames/ticket-mappings/assets/javascripts/product-mappings.js');
'use strict';

const mapProductType = (productType) => {
  switch (true) {
    case productType.includes('adventskalender'):
      return 'trissadventskalender';
    case productType.includes('skrap7'):
      return 'skrap7an';
    case productType.includes('pacman'):
    case productType.includes('pac-man'):
      return 'pacman';
    case productType.includes('triss30'):
      return 'triss30';
    case productType.includes('biltriss'): 
      return 'biltriss';
    case productType.includes('triss40'):
      return 'triss40';
    case productType.includes('triss90'):
      return 'triss90';
    case productType.includes('dubbeltriss'):
      return 'dubbeltriss';
    case productType.includes('minitriss'):
      return 'minitriss';
    case productType.includes('trisspremium'):
      return 'trisspremium';
    default:
      return productType.replace('svs', '').toLowerCase();
  }
};

const getProduct = (name, shortName, price, branding) => ({ name, shortName, price, branding });
const validateTicketId = (ticketId) => {
  let code;
  const id = ticketId.replaceAll('-', '');
  if (!new RegExp(/^[a-zA-Z0-9]*$/).test(id)) {
    code = 'INVALID_CHARS';
    throw { message: `Invalid ticketId: invalid chars (${ticketId})`, code };
  }
  if (id.length !== 27) {
    code = id.length > 27 ? 'MESSAGE_MAX' : 'MESSAGE_MIN';
    throw { message: `Invalid ticketId: invalid length ${ticketId.length} (${ticketId})`, code };
  }
};

const formatTicketId = (ticketId) => {
  try {
    validateTicketId(ticketId);
    const codeRegex = /-|\s/g;
    const stringToFormat = ticketId.replaceAll(codeRegex, '');
    const [regExp, vars] = [/^(.{5})(.{7})(.{3})(.{3})(.{9})/, '$1-$2-$3-$4-$5'];
    return stringToFormat.replace(regExp, vars).toUpperCase();
  } catch {
    return ticketId;
  }
};

const getProductByTicketId = (ticketId, shouldValidate = true) => {
  shouldValidate && validateTicketId(ticketId);
  const productCode = parseInt(ticketId.substring(0, 2), 10);
  switch (productCode) {
    case 13:
    case 46:
      return getProduct('TrissPaket 150 kr', 'trisspaket', 150, 'triss');
    case 28:
      return getProduct('Triss 40 kr', 'triss40', 40, 'triss');
    case 34:
      return getProduct('BilTriss', 'biltriss', 40, 'triss');
    case 44:
    case 54:
      return getProduct('DubbelTriss', 'dubbeltriss', 60, 'triss');
    case 1: 
    case 50:
    case 55:
    case 60:
      return getProduct('Triss 30 kr', 'triss30', 30, 'triss');
    case 53:
    case 57:
      return getProduct('MiniTriss', 'minitriss', 10, 'triss');
    case 37:
      return getProduct('Triss Nyårskalender - 300 kr', 'trissnyarskalender300', 300, 'triss');
    case 43:
      return getProduct('Triss Adventskalender - 150 kr', 'trissadventskalender150', 150, 'triss');
    case 49:
      return getProduct('Triss Adventskalender - 75 kr', 'trissadventskalender75', 75, 'triss');
    case 33:
    case 35:
      return getProduct('SkrapKryss', 'skrapkryss', 30, 'skrapspel');
    case 11:
    case 21:
      return getProduct('SkrapBingo', 'skrapbingo', 30, 'skrapspel');
    case 20:
      return getProduct('SkrapKeno', 'skrapkeno', 30, 'skrapspel');
    default:
      throw { message: `Invalid ticketId - ${productCode} (${ticketId})`, code: 'INVALID_PRODUCT_ID' };
  }
};

if (svs.isServer) {
  module.exports = {
    mapProductType,
    getProductByTicketId,
    formatTicketId
  };
} else {
  setGlobal('svs.ticketgames.ticketMappings.productMappings', {
    mapProductType,
    getProductByTicketId,
    formatTicketId
  });
}



 })(window);